<template>
  <Page>
    <template v-slot:help>
      <NotionHelp topic="inventories" />
    </template>
    <template v-slot:toolbar-buttons>
      <ButtonAction
        :action="() => modal.import.opened = true"
        icon="mdi-file-import-outline"
        text="Import"
      />
      <ButtonLink
        :href="'/admin/tasks/list/?context=' + encodeURIComponent('gid://Logistic/Inventory')"
        icon="mdi-server"
        text="Tasks"
      />
    </template>
    <template v-slot:content>
      <Filters
        @onFiltersChanged="onFiltersChanged"
      />
      <v-sheet
        elevation="3"
        rounded
      >
        <v-data-table
          :footer-props="table.footerProps"
          :headers="table.headers"
          :items="result.items"
          :loading="result.loading"
          :options.sync="table.options"
          :page.sync="table.options.page"
          :server-items-length="result.count"
          :expanded.sync="expanded"
          :single-expand="true"
          show-expand
          @item-expanded="onExpanded"
        >
          <template v-slot:loading>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.loading') }}
            </v-sheet>
          </template>
          <template v-slot:no-data>
            <v-sheet
              class="text-center pa-6"
            >
              {{ $t('global.no-data') }}
            </v-sheet>
          </template>
          <template v-slot:item.product="{ item }">
            <div class="d-flex align-center">
              <div>
                <b><ProductLink :product="item.product" /></b>
                <br>
                {{ [item.size_attributes.map(a => a.name).join('/'), item.sku].filter(v => v).join(' - ') }}
              </div>
              <BindingInfo
                v-if="item.inventory?.binding_info"
                class="ml-5"
                :value="item.inventory.binding_info"
              />
            </div>
          </template>
          <template v-slot:item.available="{ item }">
            <b :class="parseInt(item.inventory?.available_quantity) <= 0 ? 'red--text' : 'green--text'">
              {{ item.inventory?.available_quantity ?? '-' }}
            </b>
          </template>
          <template v-slot:item.sold="{ item }">
            {{ item.inventory?.sold_quantity ?? '-' }}
          </template>
          <template v-slot:item.stock="{ item }">
            {{ item.inventory?.stock ?? '-' }}
          </template>

          <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length">
              <ul>
                <li
                  v-for="(inventoryOperation, index) in expandedInventoryOperations"
                  :key="index"
                >
                  {{ inventoryOperation.executed_at|dateFull }} |
                  {{ inventoryOperation.quantity }} |
                  {{ inventoryOperation.context }}
                </li>
              </ul>
            </td>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              text
              outlined
              @click="() => { modal.edit.opened = true; editInventoryId = item.inventory.id; }"
            >
              <v-icon
                small
                color="blue lighten-1"
              >
                mdi-note-edit
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-sheet>
      <EditInventoryModal
        :inventory-id="editInventoryId"
        :opened.sync="modal.edit.opened"
        @success="onInventoryChanged"
      />
      <ImportAvailableQuantitiesModal :opened.sync="modal.import.opened" />
    </template>
  </Page>
</template>

<script>
import BindingInfo from '@/components/base/ExternalCatalog/BindingInfo.vue'
import ButtonAction from '@/components/base/Toolbar/ButtonAction.vue'
import ButtonLink from '@/components/base/Toolbar/ButtonLink.vue'
import EditInventoryModal from '@/views/Inventories/Modal/EditInventory.vue'
import Filters from '@/views/Inventories/Filters.vue'
import { getQueryString } from '@/helpers/queryString'
import ImportAvailableQuantitiesModal from '@/views/Inventories/Modal/ImportAvailableQuantities.vue'
import NotionHelp from '@/components/core/NotionHelp.vue'
import Page from '@/components/core/Page.vue'
import ProductLink from '@/components/base/Link/Product.vue'
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'InventoriesList',
  components: {
    NotionHelp,
    BindingInfo,
    ButtonLink,
    ButtonAction,
    Page,
    EditInventoryModal,
    ImportAvailableQuantitiesModal,
    ProductLink,
    Filters,
  },
  mixins: [snackbarMixin],
  data() {
    const itemsPerPage = 30
    const currentPage = +this.$route.query.page || 1
    
    return {
      modal: {
        edit: {
          opened: false,
        },
        import: {
          opened: false,
        },
      },
      editInventoryId: null,
      request: null,
      filters: {
        product_id: this.$route.query.product,
        inventory_id: this.$route.query.id,
      },
      expandedInventoryId: null,
      expandedInventoryOperations: [],
      expanded: [],
      table: {
        options: {
          page: currentPage,
          itemsPerPage: itemsPerPage,
          sortDesc: [true],
          mustSort: true,
        },
        headers: [
          {
            value: 'product',
            sortable: false,
          },
          {
            text: 'Disponible',
            value: 'available',
            sortable: false,
          },
          {
            text: 'Vendue',
            value: 'sold',
            sortable: false,
          },
          {
            text: 'Stock',
            value: 'stock',
            sortable: false,
          },
          { value: 'action' },
          { value: 'data-table-expand' },
        ],
        footerProps: {
          'items-per-page-options': [itemsPerPage],
          'show-first-last-page': true,
          'show-current-page': true,
        },
      },
      result: {
        items: [],
        count: 0,
        loading: false,
      },
    }
  },
  computed: {
    queryString: function () {
      return 'catalog/product-variants/?' + getQueryString(
        this.table.options.page,
        this.filters,
        this.table.options.sortBy,
        this.table.options.sortDesc,
        this.table.options.itemsPerPage
      )
    },
  },
  watch: {
    queryString: {
      handler() {
        this.load()
      },
    },
    expandedInventoryId () {
      this.loadExpandInventoryOperations(this.expandedInventoryId)
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    onFiltersChanged (filters) {
      this.filters = filters
      this.$router.replace({
        name: 'InventoriesList',
        query: {
          id: this.filters.inventory_id,
          product: this.filters.product_id,
          page: this.table.options.page,
        },
      })
    },
    cancel () {
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    async load () {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }

      this.result.loading = true
      this.result.items = []
      this.result.count = 0

      try {
        const response = await this.$axios.get(this.queryString, {
          cancelToken: axiosSource.token,
        })
        this.result.items = response.data['hydra:member']
        this.result.count = response.data['hydra:totalItems']
        this.result.loading = false
      } catch (error) {
        if (!this.$axios.isCancel(error)) {
          // eslint-disable-next-line no-console
          console.log(error)
        }
      } finally {
        this.result.loading = false
        this.request = null
      }
    },
    async loadExpandInventoryOperations (expandedInventoryId) {
      if (!expandedInventoryId) {
        return
      }
      this.expandedInventoryOperations = []
      const response = await this.$axios.get('logistic/inventories/' + encodeURIComponent(expandedInventoryId))
      this.expandedInventoryOperations = response.data.operations ?? []
    },
    onExpanded({ item, value }) {
      if (!value) {
        this.expandedInventoryId = null
      } else {
        this.expandedInventoryId = item.inventory?.id ?? null
      }
    },
    onInventoryChanged(inventory) {
      this.load()
      if (inventory.id === this.expandedInventoryId) {
        this.loadExpandInventoryOperations(this.expandedInventoryId)
      }
    },
  },
}
</script>
